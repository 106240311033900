<template>
    <div class="getpw">
        <p class="title">找回密码</p>

        <van-form @submit="nextStep" class="user" v-if="index === 1">
            <van-cell-group inset>
                <p class="user-name">用户名</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben48"></span>
                    <van-field v-model="account" name="account" placeholder="请输入用户名" />
                </div>
            </van-cell-group>

            <div class="user-btn">
                <van-button block type="primary" native-type="submit">下一步</van-button>
            </div>
        </van-form>
        <van-form @submit="GetPw" class="user" v-if="index === 2">
            <van-cell-group inset>
                <p class="user-name">用户名</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben48"></span>
                    <van-field v-model="account" name="account" placeholder="请输入用户名" disabled />
                </div>

                <p class="user-name">输入新密码</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben44"></span>
                    <van-field v-model="new_pass" type="password" name="new_pass" placeholder="请输入密码" />
                </div>

                <p class="user-name">确认新密码</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben44"></span>
                    <van-field v-model="submit_pass" type="password" name="submit_pass" placeholder="请再次输入密码" />
                </div>

                <p class="user-name">密保问题</p>
                <div class="flex-row">
                    <span class="label">问题</span>
                    <span class="text">{{ question }}</span>
                </div>
                <div class="flex-row">
                    <span class="label">答案</span>
                    <van-field v-model="answer" name="answer" placeholder="请输入" />
                </div>

                <p class="user-name">手机号码</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben41"></span>
                    <van-field v-model="phone" name="phone" placeholder="请输入手机号" />
                </div>

                <p class="user-name">验证码</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben12"></span>
                    <van-field v-model="smscode" name="smscode" placeholder="请输入验证码" />
                    <van-button class="sms-code" type="primary" @click="getPhoneCode" size="mini">获取验证码</van-button>
                </div>
            </van-cell-group>

            <div class="user-btn">
                <van-button block type="primary" native-type="submit">找回密码</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
import { reactive, toRefs, nextTick, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { GetImgCode, userGetPw, FindPwdQandA, SendSmsCode } from '@/api'
import md5 from 'js-md5'
import { Toast } from 'vant'

export default {
    setup() {
        const state = reactive({
            account: '',
            new_pass: '',
            submit_pass: '',
            que_id: '',
            answer: '',
            phone: '',
            smscode: '',
            index: 1,
            question: ''
        })

        const router = useRouter()

        // 获取账号对应的密保问题
        const getMibaoList = account => {
            FindPwdQandA(account).then(res => {
                if (res) {
                    state.que_id = res.data.QUE_ID
                    state.question = res.data.QUESTION
                }
            })
        }

        // 发送短信验证码
        const getPhoneCode = () => {
            if (!state.phone) {
                return Toast('请输入手机号码')
            }
            SendSmsCode(state.phone).then(res => {
                Toast(res.message)
            })
        }

        // 下一步
        const nextStep = () => {
            if (!state.account) {
                Toast('请输入用户名')
            } else {
                getMibaoList(state.account)
                state.index = 2
            }
        }

        const validateForm = () => {
            if (state.submit_pass !== state.new_pass) {
                Toast('确认密码与新密码输入不一致，请确认')
                return false
            }
            return true
        }

        // 找回密码确认
        const GetPw = params => {
            if (!validateForm()) {
                return
            }
            userGetPw({
                account: params.account,
                new_pass: md5(params.new_pass),
                que_id: state.que_id,
                answer: params.answer,
                phone: params.phone,
                smscode: params.smscode
            }).then(res => {
                if (res.status) {
                    Toast.success(res.message)
                    router.push('/Login')
                } else {
                    Toast.fail(res.message)
                }
            })
        }

        nextTick(() => {
            document.removeEventListener('touchmove', window.touchmoveListener, { passive: false })
        })
        onUnmounted(() => {
            document.addEventListener('touchmove', window.touchmoveListener, { passive: false })
        })

        return {
            ...toRefs(state),
            router,
            GetPw,
            nextStep,
            getPhoneCode
        }
    }
}
</script>

<style lang="scss" scoped>
.getpw {
    min-height: 0 !important;
    text-align: center;
    color: #000000;

    .logo {
        margin-top: h(12);
        padding: 0 w(30);

        .logo-img {
            margin-right: w(7);
            vertical-align: -15px;
        }
    }

    .title {
        padding: 0 w(30);
        font-size: 20px;
        margin-top: h(30);
        color: #6d88fb;
        font-weight: bold;
        text-align: left;
    }

    .user {
        padding: 0 w(30);
        margin: h(20) 0 h(34) 0;

        .flex-row {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            height: h(40);
            line-height: h(50);

            .label {
                width: 5em;
                text-align: left;
            }

            .text {
                flex: 1;
                text-align: left;
            }

            .sms-code {
                position: absolute;
                right: 0;
            }
        }

        .user-name {
            margin-top: h(21);
            text-align: left;
            color: #333333;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
        }

        .user-btn {
            margin-top: h(30);
            font-size: 18px;
        }
    }
}
</style>
